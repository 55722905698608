import React, { Suspense, useState } from 'react';
import styles from './CompanyTeams.module.scss';
import Teams from '../Teams/Teams';
import { useDeleteTeamByIdMutation, useGetTeamsByCompanyIdQuery } from 'api/team';
import { Button, Loader } from 'components/ui';
import { getCurrentCompanyIdSelector, getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { handleAsync } from 'helpers';
import Search from 'components/atoms/Search/Search';
import { useNavigate } from 'react-router-dom';
import profileTeam from 'assets/images/profileTeam.webp';
import { useTranslation } from 'react-i18next';
function CompanyTeams() {
  const companyId = useSelector(getCurrentCompanyIdSelector);
  const profileId = useSelector(getCurrentUserProfileId);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteTeamById] = useDeleteTeamByIdMutation();
  const { t } = useTranslation();

  // useEffects & custom hooks

  // data fetching

  // TODO: add pagination here
  const {
    isLoading,
    isError,
    data: teams,
    error
  } = useGetTeamsByCompanyIdQuery({
    companyId,
    page,
    size: 1000,
    searchQuery
  });

  // others

  const onTeamDelete = async (id) => {
    return (
      await handleAsync(
        deleteTeamById({
          teamId: id
        }).unwrap()
      )
    )[0];
  };

  const content = (
    <div className={styles.team_page_wrap}>
      <Suspense fallback={<Loader fullpage={true} />}>
        <div
          className={
            teams?.result.length > 0 || searchQuery
              ? styles.teamBanner
              : `${styles.noTeamBanner} ${styles.teamBanner}`
          }
        >
          {teams?.result.length > 0 || searchQuery ? (
            <div className={styles.teamHeader}>
              <div className={styles.headerTitle}>
                <h1 className={styles.title}>
                  {t('Connecting Teams through')} <span> WiZR X </span>
                </h1>
                <Search
                  placeholder={t('Search teams')}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  limit={50}
                />
              </div>
              <img className={styles.teamImg} src={profileTeam} alt="team" />
            </div>
          ) : (
            <div className={styles.noTeam}>
              <h1 className={styles.team}>
                {t('Create a Team and')} <br />
                <span className={styles.desc}>{t('Enjoy the Power of Collaboration')}</span>
              </h1>
              <Button onClick={() => navigate('/profiles')} bgColor="#2e6055">
                {t('Create Team')}
              </Button>
            </div>
          )}
        </div>
        <div className={styles.teamsWrapper}>
          {isLoading && <Loader loading={isLoading} />}
          {teams &&
            teams.result
              .filter((item) => {
                if (searchQuery == '') {
                  return true;
                }
                return (
                  searchQuery.toLowerCase() ===
                  item.name.substring(0, searchQuery.length).toLowerCase()
                );
              })
              .map((item) => (
                <Teams
                  deleteTeamById={onTeamDelete}
                  profiles={item.profiles}
                  name={item.name}
                  id={item.id || item._id}
                  key={item.id}
                  overview={item.overview}
                  purpose={item.purpose}
                  description={item.description}
                  department={item.department}
                  isCreatedByYou={item.profileId === profileId}
                />
              ))}
        </div>
      </Suspense>
    </div>
  );

  return content;
}

export default CompanyTeams;
