import React, { useState } from 'react';
import styles from './NavProfile.module.scss';
import { ProfilePic } from 'components/ui';
import { capitalizeFirstLetter } from 'helpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  getCurrentCompanyIdSelector,
  getCurrentUserIdSelector,
  getCurrentUserProfileId
} from 'store/login.slice';
import { deleteAuthData, switchActiveProfile } from 'helpers/auth';
import Smile from 'assets/icons/updateStatus.svg?react';
import SwitchAccount from 'assets/icons/switchAccount.svg?react';
import EditProfile from 'assets/icons/editProfile.svg?react';
import Logout from 'assets/icons/logoutSvg.svg?react';
import Support from 'assets/icons/supportSvg.svg?react';
import { useTranslation } from 'react-i18next';
import CustomRadioButton from 'components/atoms/CustomRadioButton/CustomRadioButton';
import { useNavigate } from 'react-router-dom';
function NavProfileModel({ image, onUpdateStatusClick, status, contact, userProfiles }) {
  const currentUserId = useSelector(getCurrentUserIdSelector);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUserProfile = useSelector(getCurrentUserProfileId);
  const currentCompanyId = useSelector(getCurrentCompanyIdSelector);
  const [selectedProfile, setSelectedProfile] = useState(currentCompanyId);
  console.log(selectedProfile);
  const handleSwitchAccount = (id) => {
    if (id === currentCompanyId) return;
    setSelectedProfile(id);
    switchActiveProfile(id);
  };
  return (
    <>
      <div className={styles['nav-profile-model']}>
        <div className={styles['wrapper']}>
          <div className={styles['name-picture']}>
            <ProfilePic
              clickable
              profileId={currentUserProfile}
              extraClass="navprofile"
              image={image}
            />
            <div className={styles['name-and-status']}>
              <Link className={styles.nameLink} to={`/profile/${currentUserProfile}`}>
                {capitalizeFirstLetter(contact.firstName) +
                  ' ' +
                  capitalizeFirstLetter(contact.lastName)}
              </Link>
              <div className={styles.statusWrapper}>
                <div className={`${styles['status-circle']} ${styles[status.toLowerCase()]}`} />
                <h5>{capitalizeFirstLetter(status)}</h5>
              </div>
            </div>
          </div>
          <div className={styles.accountSwitch}>
            <div className={styles.accountHeader}>
              <SwitchAccount />
              <span>Switch account</span>
            </div>
            <div className={styles.accountList}>
              {userProfiles?.map((profile) => {
                return (
                  <label
                    key={profile?.company?.id}
                    className={styles.accountOption}
                    onClick={() => handleSwitchAccount(profile?.company?.id)}
                  >
                    <span key={profile?.company?.id} className={styles.accountName}>
                      {profile?.company?.name}
                    </span>
                    <CustomRadioButton
                      key={profile?.company?.id}
                      checked={selectedProfile === profile?.company?.id}
                    />
                  </label>
                );
              })}
            </div>
          </div>
          <div
            onClick={() => {
              navigate(`/profile/${currentUserProfile}`);
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <EditProfile />
            </div>
            <button className={styles.updateBtn} defaultValue="Edit Your Profile" name="Update">
              {t('Edit Your Profile')}
            </button>
          </div>
          <div
            onClick={() => {
              onUpdateStatusClick();
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <Smile />
            </div>
            <button className={styles.updateBtn} defaultValue="Update Your Status" name="Update">
              {t('Update Your Profile Status')}
            </button>
          </div>
          <div
            onClick={() => {
              window.open('https://support.masterwizr.com', '_blank');
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <Support />
            </div>
            <button className={styles.updateBtn} defaultValue="Logout" name="logout">
              {t('Support Center')}
            </button>
          </div>
          <div
            onClick={() => {
              deleteAuthData();
            }}
            className={styles.updateStatus}
          >
            <div className={styles.icon}>
              <Logout />
            </div>
            <button className={styles.updateBtn} defaultValue="Logout" name="logout">
              {t('Logout')}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavProfileModel;
