import { showErrorToast } from 'utils/toast';
import { useUpdateIndividualProfileMutation } from 'api/individual-profile';
import { deleteFromS3, handleAsync } from 'helpers';
import { useUploadBlobs } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';

function useInspireInstructUtils({ inspireInstructText, mediaLink }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [description, setDescription] = useState(inspireInstructText);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mediaBlob, setMediaBlob] = useState(null);
  const [link, setLink] = useState(mediaLink);
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const [updateIndividualProfile] = useUpdateIndividualProfileMutation();

  async function handleUpload(file) {
    if (!file?.type.includes('image')) {
      return showErrorToast('Please upload an Image only');
    }
    setMediaBlob(file);
  }

  const handleMediaUpload = async () => {
    if (!mediaBlob) return;
    // upload the video
    const [status, response] = await handleAsync(
      uploadBlobs([mediaBlob], 'inspire_instruct_media')
    );
    const keys = response?.keys ?? [];
    setLink(keys[0]);
    // check if the video was uploaded
    if (!status) {
      const error = typeof response === 'string' ? response : 'Error uploading media';
      return showErrorToast(error);
    }
    const result = await handleAsync(
      updateIndividualProfile({
        profile: { motivation: { link: keys[0], text: description } },
        key: 'motivation'
      }).unwrap()
    );
    // check if the video link was updated successfully and delete the old video
    if (result[0] && mediaLink) {
      handleAsync(deleteFromS3(mediaLink));
    }
  };
  return {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    link,
    setIsModalOpen,
    isUploading,
    totalUploadProgress,
    updateIndividualProfile,
    handleUpload,
    handleMediaUpload
  };
}

export default useInspireInstructUtils;
