import React, { useState } from 'react';
import NotesEditor from 'components/organisms/Course/components/PagesView/Notes/Editor';
import style from './styles.module.scss';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import Options from 'components/atoms/Options/Options';
import { EditInput } from 'components/form';

export default function Index() {
  const [notes, setNotes] = useState([]);
  const [data, setData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [currentEditValue, setCurrentEditValue] = useState('');
  const [editingIndex, setEditingIndex] = useState(null);

  const handleDeleteNote = () => {
    if (noteToDelete !== null) {
      setNotes((prevNotes) => prevNotes.filter((_, index) => index !== noteToDelete));
      setNoteToDelete(null);
      setDeleteModal(false);
    }
  };

  const handleAddNote = (newNote) => {
    setNotes([newNote, ...notes]);
    setData('');
  };

  const handleEdit = (index, noteText) => {
    setEdit(true);
    setEditingIndex(index);
    setCurrentEditValue(noteText);
  };

  const handleInputChange = (e) => {
    setCurrentEditValue(e.target.value);
  };

  const handleSaveNote = () => {
    if (editingIndex !== null) {
      setNotes((prevNotes) =>
        prevNotes.map((note, index) =>
          index === editingIndex
            ? currentEditValue.split('\n').map((line) => ({
                children: [{ text: line }],
                align: 'left'
              }))
            : note
        )
      );
    }
    setEdit(false);
    setEditingIndex(null);
    setCurrentEditValue('');
  };

  return (
    <div>
      <NotesEditor
        placeholderText="Add a note..."
        data={data}
        setData={setData}
        onAddNote={handleAddNote}
      />
      <div className={style.notesContainer}>
        {notes.map((note, index) => (
          <div key={index} className={style.note}>
            {edit && editingIndex === index ? (
              <div className={style.inputs}>
                <EditInput
                  fontWeight="normal"
                  fontSize="1rem"
                  extraClass="noteInput"
                  value={currentEditValue}
                  onChange={handleInputChange}
                />
                <div className={style.buttons}>
                  <button onClick={handleSaveNote}>Save</button>
                  <button onClick={() => setEdit(false)}>Cancel</button>
                </div>
              </div>
            ) : (
              <>
                {note.map((block, i) => (
                  <div key={i} style={{ textAlign: block.align || 'left' , width: "100%"}}>
                    {block.children.map((leaf, j) => (
                      <span
                        key={j}
                        style={{
                          fontWeight: leaf.bold ? 'bold' : 'normal',
                          fontStyle: leaf.italic ? 'italic' : 'normal',
                          textAlign: block.align || 'left'
                        }}
                      >
                        {leaf.text}
                      </span>
                    ))}
                    {block?.type === 'image' && <img src={block.url} alt="media" />}
                    {block?.type === 'video' && (
                      <video controls>
                        <source src={block.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                ))}
              </>
            )}
            <div className={style.dots}>
              <Options
                handleEdit={() =>
                  handleEdit(
                    index,
                    note
                      .map((block) => block.children.map((leaf) => leaf.text).join(' '))
                      .join('\n')
                  )
                }
                extraClass="notesOptions"
                handleDelete={() => {
                  setNoteToDelete(index);
                  setDeleteModal(true);
                }}
              />
            </div>
          </div>
        ))}
      </div>

      {deleteModal && (
        <DeleteModal
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDeleteNote}
          title="Are you sure you want to delete this note?"
        />
      )}
    </div>
  );
}
