import React, { useCallback, useEffect, useState } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor, Transforms, Editor } from 'slate';
import { Toolbar } from 'components/atoms/MWEditor/Components';
import { toggleMark } from 'components/atoms/MWEditor/Helpers';
import Element from 'components/atoms/MWEditor/Element';
import Leaf from 'components/atoms/MWEditor/Leaf';
import { HOTKEYS } from 'components/atoms/MWEditor/constants';
import MarkButton from 'components/atoms/MWEditor/MarkButton';
import BlockButton from 'components/atoms/MWEditor/BlockButton';
import styles from '../../../../../atoms/MWEditor/MWEditor.module.scss';
import Bold from 'assets/icons/bold.svg?react';
import Italic from 'assets/icons/italicicon.svg?react';
import Left from 'assets/icons/lefticon.svg?react';
import Center from 'assets/icons/centericon.svg?react';
import Right from 'assets/icons/righticon.svg?react';
import style from './styles.module.scss';
import { Button } from 'components/ui';
import { InputButton } from 'components/atoms/MWEditor/InputButton';
import isHotkey from 'helpers/isHotkey';

const NotesEditor = ({ placeholderText, data, setData, onAddNote, ...rest }) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()));
  const inputFileRef = React.useRef(null);

  const toggleBlockAlignment = (alignment) => {
    Transforms.setNodes(
      editor,
      { align: alignment },
      { match: (n) => Editor.isBlock(editor, n), split: true }
    );
  };

  const handleFileAddition = (files) => {
    const file = files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const url = reader.result;
      const fileType = file?.type.split('/')[0];

      if (fileType === 'image' || fileType === 'video') {
        const mediaElement = {
          type: fileType,
          url,
          children: [{ text: '' }],
        };

        Transforms.insertNodes(editor, mediaElement);
        Editor.focus(editor);
      }
    };

    reader.readAsDataURL(file);
  };

  const handlePostClick = () => {
    if (editor.children && onAddNote) {
      const serializedContent = editor.children.map((node) => {
        const { align, type, children, url } = node;
  
        return {
          type: type || 'paragraph',
          align: align || 'left',
          url: url || null, 
          children: children.map((child) => ({
            ...child,
          })),
        };
      });

      onAddNote(serializedContent);
      setData(serializedContent);
    }
  };

  useEffect(() => {
    if (!data) {
      setData(initialValue);
    }
  }, [data]);

  return (
    <div className={`${styles.container} notesEditor`}>
      <Slate editor={editor} initialValue={data || initialValue} onChange={(value) => setData(value)}>
        <Toolbar>
          <MarkButton format="bold" icon={<Bold />} className={styles.editorIcon} tooltip="Bold" />
          <MarkButton
            format="italic"
            icon={<Italic />}
            className={styles.editorIcon}
            tooltip="Italic"
          />
          <BlockButton
            format="left"
            icon={<Left />}
            className={styles.editorIcon}
            tooltip="Align left"
            onClick={() => toggleBlockAlignment('left')}
          />
          <BlockButton
            format="center"
            icon={<Center />}
            className={styles.editorIcon}
            tooltip="Align center"
            onClick={() => toggleBlockAlignment('center')}
          />
          <BlockButton
            format="right"
            icon={<Right />}
            className={styles.editorIcon}
            tooltip="Align right"
            onClick={() => toggleBlockAlignment('right')}
          />
          <InputButton
            inputFileRef={inputFileRef}
            tooltip="Image or Video"
            handleFileAddition={handleFileAddition}
            icon={true}
          />
        </Toolbar>
        <Editable
          {...rest}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={placeholderText}
          spellCheck
          autoFocus
          className={`${styles.editor} ${styles.open} textBox`}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
      <div className={style.lower_panel}>
        <Button bgColor="#37363687" onClick={handlePostClick}>
          Post
        </Button>
      </div>
    </div>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export default NotesEditor;
