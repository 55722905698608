import React, { useCallback, useState } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor, Transforms } from 'slate';
import { Toolbar } from './Components';
import { toggleMark } from './Helpers';
import Element from './Element';
import Leaf from './Leaf';
import { HOTKEYS } from './constants';
import MarkButton from './MarkButton';
import BlockButton from './BlockButton';
import styles from './MWEditor.module.scss';
import Bold from 'assets/icons/bold.svg?react';
import NumList from 'assets/icons/listicon.svg?react';
import BulletList from 'assets/icons/bullet.svg?react';
import H1 from 'assets/icons/h1icon.svg?react';
import Italic from 'assets/icons/italicicon.svg?react';
import SvgPost from 'assets/icons/post_svg.svg?react';
import Left from 'assets/icons/lefticon.svg?react';
import Center from 'assets/icons/centericon.svg?react';
import Underline from 'assets/icons/underlineee.svg?react';
import Right from 'assets/icons/righticon.svg?react';
import Quote from 'assets/icons/quote.svg?react';
import Emoji from 'assets/icons/emoji.svg?react';
import Save from 'assets/icons/save.svg?react';
import Poll from 'assets/icons/poll.svg?react';
import Visuals from 'assets/icons/visuals.svg?react';
import Gif from 'assets/icons/gif.svg?react';
import Tabs from 'assets/icons/tabs.svg?react';
import Add from 'assets/icons/addicon.svg?react';
import { useCreatePostMutation, useUpdatePostByIdMutation } from 'api/social-board';
import Picker from 'emoji-picker-react';
import { Theme } from 'emoji-picker-react';
import { getS3Url, deleteFromS3 } from 'helpers/s3Handler';
import { isImageURL } from 'helpers/index.js';
import QueryableProfiles from 'molecules/QueryableProfiles/QueryableProfiles';
import EmployeeCard from 'components/molecules/EmployeeCard/EmployeeCard';
import { showErrorToast, showSuccessToast } from 'utils/toast';
import { handleAsync } from 'helpers';
import { CustomDropdown } from 'components/form';
import { POST_CATEGORIES } from 'constants/socialBoards';
import PollEditor from 'molecules/PollEditor/PollEditor';
import GifPickerModal from 'components/molecules/GifPickerModal/GifPickerModal';
import { Button, Progress } from 'components/ui';
import NewsCovers from 'components/molecules/NewsCovers/NewsCovers';
import { MEDIA_TYPES } from 'constants';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { useUploadBlobs } from 'hooks';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { APP_TIERS } from 'constants';
import { showWarnToast } from 'utils/toast';
import { maxWords } from 'helpers/general';
import { BasicTooltip } from '../Tooltip/Tooltip';
import { SpecialButton } from './SpecialButton';
import { InputButton } from './InputButton';
import isHotkey from 'helpers/isHotkey';
import { createPortal } from 'react-dom';

// TODO refactor this Component to be more readable
const MWEditor = ({
  toBeUpdated,
  cancel,
  postId,
  mediaLinks,
  postedProfiles,
  category,
  postPollToBeUpdated,
  coverToBeUpdated
}) => {
  const userStatus = useSelector(getUserStatusSelector);
  const { currentStep, setCurrentStep } = useTour();
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [editor] = useState(() => withReact(createEditor()), []);
  const [showPicker, setShowPicker] = useState(false);
  const [postMedia, setPostMedia] = useState([]);
  const [postCover, setPostCover] = useState(coverToBeUpdated ? coverToBeUpdated : undefined);
  const [postPoll, setPostPoll] = useState(
    postPollToBeUpdated ? JSON.parse(JSON.stringify(postPollToBeUpdated.questions)) : []
  ); //editted polls need to be copied, stringfying and parsing is the best way I know to do this
  const [pollEditorVisible, setPollEditorVisible] = useState(
    postPollToBeUpdated?.questions?.length ? true : false
  );
  const [gifPickerVisible, setGifPickerVisible] = useState(false);
  const [coversModal, setCoversModal] = useState(false);
  const [postProfiles, setPostProfiles] = useState(postedProfiles || []);
  const inputFileRef = React.useRef(null);
  const [mediaToBeDeleted, setMediaToBeDeleted] = useState([]);
  const [createPost, { isLoading: isSaving }] = useCreatePostMutation();
  const [updatePostById, { isLoading: isUpdating }] = useUpdatePostByIdMutation();
  //TODO what's the point of using user id here ?
  const userId = userStatus.user._id;
  const profileId = userStatus.profileStatus.id;
  const companyId = userStatus.company.id;
  const [showToolbar, setShowToolbar] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const { t } = useTranslation();
  const isUploadingOrUpdating = isSaving || isUpdating || isUploading;
  const ctgrs = POST_CATEGORIES.filter((ctgr) => ctgr.text);
  const [selectableCategories, setSelectableCategories] = useState([...ctgrs]);
  const { tierData } = useGetUserTierData();
  const userTier = tierData?.tier;
  const [toastShown, setToastShown] = useState(false);
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const selectCategory = (categoryToBeSelected) => {
    let Categories = selectableCategories;
    if (categoryToBeSelected == selectedCategory) {
      Categories.forEach((category) => {
        category.icon = '';
      });
      setSelectableCategories(Categories);
      setSelectedCategory('');
    } else {
      Categories.forEach((category) => {
        if (category.text == categoryToBeSelected) {
          category.icon = <Save />;
        } else {
          category.icon = '';
        }
      });
      setSelectableCategories(Categories);
      setSelectedCategory(categoryToBeSelected);
    }
  };

  const addEmoji = (emoji) => {
    editor.insertText(emoji.emoji);
    setShowPicker(false);
  };
  const saveContent = debounce(async () => {
    let selectedCover = '';
    if (coverToBeUpdated && postCover == coverToBeUpdated) {
      selectedCover = coverToBeUpdated;
    } else if (postCover != undefined) {
      selectedCover = postCover;
    }
    if (postPoll?.length) {
      //looks like we have a poll, lets check that its full
      let postValid = true;
      for (let pollIndex = 0; pollIndex < postPoll.length; pollIndex++) {
        const poll = postPoll[pollIndex];
        if (!poll.question) {
          showErrorToast(t('please-make-sure-your-polls'));
          postValid = false;
          break;
        }
        for (let optionsIndex = 0; optionsIndex < poll.options.length; optionsIndex++) {
          const option = poll.options[optionsIndex];
          if (option.value === '') {
            showErrorToast(t('please-make-sure-your-polls-options'));
            postValid = false;
            break;
          }
        }
      }
      if (!postValid) return;
    }
    // if (editor.children[0].children[0].text === '' && editor.children.length == 1) return;
    const text = editor.children[0].children[0].text;
    if (userTier === APP_TIERS.LITE && maxWords(200, text))
      return showErrorToast('Your License does not allow adding more than 200 words');
    let mediaToBeSaved = [];
    let profileIds = [];
    const files = postMedia.map((media) => media.file);
    const [status, result] = await handleAsync(uploadBlobs(files, `postContents`));
    const keys = result?.keys ?? [];
    if (!status) {
      keys.forEach((key) => {
        handleAsync(deleteFromS3(key));
      });
      const error = typeof result === 'string' ? result : t('something-went-wrong-while-uploading');
      return showErrorToast(error);
    }
    keys.forEach((key, index) => {
      mediaToBeSaved.push({ type: files[index]?.type, link: key });
    });

    for (const profile of postProfiles) {
      profileIds.push(profile.id);
    }
    if (toBeUpdated) {
      if (mediaToBeDeleted) {
        mediaToBeDeleted.forEach((media) => {
          cleanUploads(media);
        });
      }
      //append our previous media
      for (const media of mediaLinks) {
        if (!mediaToBeDeleted.includes(media.link)) {
          mediaToBeSaved.push(media);
        }
      }
      cancel();
      const result = await handleAsync(
        updatePostById({
          oldCategory: category,
          postId: postId,
          post: {
            userId: userId,
            topic: 'Topic',
            media: mediaToBeSaved,
            content: JSON.stringify(editor.children),
            profile: profileId,
            companyId: companyId,
            comments: [],
            profiles: profileIds,
            category: selectedCategory,
            commentsCount: 0,
            poll: { questions: postPoll },
            cover: selectedCover
          }
        }).unwrap()
      );
      if (result[0]) showSuccessToast(t('your-post-is-updated'));
    } else {
      const result = await handleAsync(
        createPost({
          post: {
            userId: userId,
            media: mediaToBeSaved,
            tags: [],
            topic: 'Topic',
            content: JSON.stringify(editor.children),
            profile: profileId,
            companyId: companyId,
            comments: [],
            profiles: profileIds,
            category: selectedCategory,
            commentsCount: 0,
            poll: { questions: postPoll },
            cover: selectedCover
          }
        }).unwrap()
      );
      if (result[0]) {
        showSuccessToast(t('you-added-a-new-post'));
        reset();
        setPostMedia([]);
        setPostProfiles([]);
        setMediaToBeDeleted([]);
        setPollEditorVisible(false);
        setPostPoll([]);
        setPostCover(undefined);
      }
    }
  }, 400);

  const reset = () => {
    // loop delete all
    editor.children.map((item) => {
      Transforms.delete(editor, { at: [0] });
    });
    Transforms.insertNodes(editor, initialValue);
  };

  const handleFileAddition = (files, filesToBeAdded, index) => {
    if (index >= files.length) {
      inputFileRef.current.value = '';
      setPostMedia([...postMedia, ...filesToBeAdded]);
      return;
    }
    let file = files[index];
    if (
      mediaLinks?.find((item) => {
        const regex = /.*\//;
        const slug = item?.link.replace(regex, '');
        return slug === file.name;
      })
    ) {
      inputFileRef.current.value = '';
      return;
    }

    if (
      postMedia.find((current) => {
        return current.file.name === file.name;
      })
    ) {
      inputFileRef.current.value = '';
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    if (file?.type.includes('image/')) {
      filesToBeAdded.push({ file, objectUrl, type: MEDIA_TYPES.IMAGE });
    }
    if (file?.type.includes('video/')) {
      filesToBeAdded.push({ file, objectUrl, type: MEDIA_TYPES.VIDEO });
    }
    handleFileAddition(files, filesToBeAdded, index + 1);
  };

  async function cleanUploads(link) {
    await handleAsync(deleteFromS3(link));
  }

  const removeMedia = (media) => {
    URL.revokeObjectURL(media.objectUrl);
    setPostMedia(
      postMedia.filter((current) => {
        return current.objectUrl !== media.objectUrl;
      })
    );
  };

  function toggleMediaToBeDeleted(media) {
    if (mediaToBeDeleted.includes(media.link)) {
      setMediaToBeDeleted(
        mediaToBeDeleted.filter((current) => {
          return current !== media.link;
        })
      );
    } else {
      setMediaToBeDeleted([...mediaToBeDeleted, media.link]);
    }
  }
  if (category && selectedCategory == '') {
    selectCategory(category);
  }

  function insertGifImages(url) {
    const element = {
      type: 'image',
      url,
      children: [{ text: '' }],
      contextSensitiveFunction: () => {
        const path = '';
        editor.children.map((item, path) => {
          if (item?.type === 'image' && item.url == url) {
            Transforms.delete(editor, { at: [path] });
          }
        });
      }
    };

    Transforms.insertNodes(editor, element);
  }

  if (toBeUpdated) {
    let toBeHandled = [];
    editor.children.map((child, index) => {
      if (child?.type == 'gif') {
        let elementObj = { index: index, url: child.url };
        toBeHandled.push(elementObj);
      }
    });

    toBeHandled.forEach((pathToElement) => {
      const elementToBeSet = {
        type: 'gif',
        url: pathToElement.url,
        children: [{ text: '' }],
        contextSensitiveFunction: () => {
          const path = '';
          editor.children.map((item, path) => {
            if (item?.type === 'gif' && item.url == pathToElement.url) {
              Transforms.delete(editor, { at: [path] });
            }
          });
        }
      };
      Transforms.setNodes(editor, elementToBeSet, { at: [pathToElement.index] });
    });
  }

  const insertQuote = () => {
    setShowPlaceholder(false);
  };

  return (
    <div className={styles.container}>
      <Progress
        height="15px"
        label={t('uploading-content')}
        total={totalUploadProgress.total}
        loaded={totalUploadProgress.loaded}
      />
      <Slate editor={editor} initialValue={toBeUpdated ? toBeUpdated : initialValue}>
        {showToolbar && (
          <Toolbar>
            <div className={styles.emojicontainer}>
              <Emoji
                className={styles.emoji}
                onClick={() => {
                  setShowPicker(!showPicker);
                }}
              />
              {showPicker && (
                <div className={styles.pickercontainer}>
                  <Picker
                    className={styles.picker}
                    onEmojiClick={addEmoji}
                    theme={Theme.DARK}
                    lazyLoadEmojis={true}
                  />
                </div>
              )}
            </div>
            <MarkButton
              format="bold"
              icon={<Bold />}
              className={styles.editorIcon}
              tooltip="Bold"
            />
            <MarkButton
              format="italic"
              icon={<Italic />}
              className={styles.editorIcon}
              tooltip="Italic"
            />
            <MarkButton
              format="underline"
              icon={<Underline />}
              className={styles.editorIcon}
              tooltip="Underline"
            />
            <BlockButton
              format="heading-one"
              icon={<H1 />}
              className={styles.editorIcon}
              tooltip="H1"
            />
            <BlockButton
              format="block-quote"
              icon={<Quote />}
              className={styles.editorIcon}
              tooltip="Quote"
              clickEvent={insertQuote}
            />
            <BlockButton
              format="numbered-list"
              icon={<NumList />}
              className={styles.editorIcon}
              tooltip="Numbered list"
            />
            <BlockButton
              format="bulleted-list"
              icon={<BulletList />}
              className={styles.editorIcon}
              tooltip="Bulleted list"
            />
            <BlockButton
              format="left"
              icon={<Left />}
              className={styles.editorIcon}
              tooltip="Align left"
            />
            <BlockButton
              format="center"
              icon={<Center />}
              className={styles.editorIcon}
              tooltip="Align center"
            />
            <BlockButton
              format="right"
              icon={<Right />}
              className={styles.editorIcon}
              tooltip="Align right"
            />
            <SpecialButton
              editorVisible={pollEditorVisible}
              icon={<Poll />}
              setEditorVisible={setPollEditorVisible}
              text={'Poll'}
              tooltip={'Poll'}
              className={styles.editorIcon}
            />
            <SpecialButton
              editorVisible={gifPickerVisible}
              icon={<Gif style={{ width: '16px', height: '16px' }} />}
              setEditorVisible={setGifPickerVisible}
              text={'Gif'}
              tooltip={'Gif'}
              className={styles.editorIcon}
            />
            <InputButton
              handleFileAddition={handleFileAddition}
              inputFileRef={inputFileRef}
              postId={postId}
              tooltip={'Image Or Video'}
              className={styles.editorIcon}
            />
            <QueryableProfiles
              rtkQueryName={'getProfilesByKeyword'}
              setProfiles={setPostProfiles}
              selection={postProfiles}
              key={postProfiles.length}
            />
            <div
              className={styles.categories}
              onClick={() => setCurrentStep(currentStep + 1)}
              data-tooltip-id="categories-tooltip"
            >
              <div id='portal-category-icon'></div>
              <CustomDropdown
                Icon={
                  document.getElementById('portal-category-icon') &&
                  createPortal(<Tabs className={styles.custom_svg} />, document.getElementById('portal-category-icon'))}
                extraClass={'categoryPicker'}
                extraInnerClass={'categoryPickerInner'}
                list={selectableCategories}
                onChange={(text) => {
                  selectCategory(text);
                }}
              />
              <BasicTooltip id={`categories-tooltip`} variant={'dark'} content={t('Categories')} />
            </div>
            <div
              data-tooltip-id={'visuals-tooltip'}
              className={styles.coverIcon}
              onClick={() => {
                setCoversModal(true);
                setCurrentStep(currentStep + 1);
              }}
            >
              <Visuals />
              <BasicTooltip id={`visuals-tooltip`} variant={'dark'} content={t('Pick Visuals')} />
            </div>
          </Toolbar>
        )}
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder={showPlaceholder ? t('whats-on-your-mind') : ''}
          spellCheck
          autoFocus
          className={showToolbar ? `${styles.editor} ${styles.open}` : `${styles.editor}`}
          onKeyDown={(event) => {
            const text = editor.children[0].children[0].text;
            if (userTier === APP_TIERS.LITE && maxWords(200, text)) {
              if (
                event.key !== 'Backspace' &&
                event.key !== 'Delete' &&
                event.key !== 'ArrowLeft' &&
                event.key !== 'ArrowRight' &&
                event.key !== 'Enter' &&
                !event.ctrlKey &&
                !event.metaKey
              ) {
                if (!toastShown) {
                  showWarnToast('Your License Does not allow adding more than 200 words.');
                  setToastShown(true);
                }
                event.preventDefault();
              }
            }

            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
              saveContent();
            }
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
          onFocus={() => {
            setShowToolbar(true);
          }}
        />
        {postCover && (
          <div className={styles.CoverPreview}>
            <Add
              className={styles.deletecontent}
              onClick={() => {
                setPostCover('');
              }}
            />
            <img
              alt="cover image"
              src={
                !coverToBeUpdated
                  ? postCover
                  : coverToBeUpdated == postCover
                    ? getS3Url(postCover)
                    : postCover
              }
            />
          </div>
        )}
        {showToolbar && (
          <div className={styles.bottomcontainer}>
            {toBeUpdated ? (
              <Button
                marginBottom={'10px'}
                color="white"
                bgColor="#343434"
                className={styles.saver}
                onClick={() => cancel()}
              >
                {t('Cancel')}
              </Button>
            ) : (
              <></>
            )}
            <Button
              disabled={isUploadingOrUpdating}
              color="white"
              bgColor="#343434"
              className={styles.saver}
              onClick={() => saveContent()}
            >
              <SvgPost />
              {isUploadingOrUpdating ? t('loading...') : !toBeUpdated ? t('Post') : t('Post')}
            </Button>
          </div>
        )}
      </Slate>

      {postMedia.length || mediaLinks?.length ? (
        <div className={styles.files}>
          {postMedia.map((media, index) => {
            return (
              <div className={styles.mediacontainer} key={media.objectUrl}>
                <Add
                  className={styles.deletecontent}
                  onClick={() => {
                    removeMedia(media);
                  }}
                />
                {media?.type === MEDIA_TYPES.VIDEO ? (
                  <video controls src={media.objectUrl} />
                ) : (
                  <img src={media.objectUrl} alt="dynamic image" />
                )}
              </div>
            );
          })}
          {mediaLinks?.map((media, index) => {
            const url = getS3Url(media.link);
            const extention = url.split('.').pop();
            const urlIsImage = isImageURL(extention.toLowerCase());
            if (mediaToBeDeleted.includes(media.link)) {
              return <></>;
            }
            return (
              <div
                className={
                  mediaToBeDeleted.includes(media.link)
                    ? styles.mediacontainerdelete
                    : styles.mediacontainer
                }
                key={media.link}
              >
                <Add
                  className={styles.deletecontent}
                  onClick={() => {
                    toggleMediaToBeDeleted(media);
                  }}
                />
                {urlIsImage ? <img src={url} alt="dynamic image" /> : <video controls src={url} />}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {postProfiles.length || postedProfiles?.length ? (
        <div
          className={
            postProfiles.length >= 5
              ? `${styles.fiveProfilesRow} ${styles.profiles}`
              : styles.profiles
          }
        >
          {postProfiles?.map((profile, index) => {
            return (
              <div className={styles.mediacontainer} key={profile.id}>
                <Add
                  className={styles.deletecontent}
                  onClick={() => {
                    setPostProfiles(postProfiles.filter((i) => i.id !== profile.id));
                  }}
                />
                <EmployeeCard
                  showContactButton
                  showProfileButton
                  key={profile.id}
                  {...profile}
                  disableCheckbox={true}
                  extraClass={
                    postProfiles.length >= 5
                      ? 'fiveCards'
                      : postProfiles.length < 5
                        ? 'profileCard'
                        : 'grid-card'
                  }
                />
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
      {/* POLL HERE */}
      {pollEditorVisible && (
        <PollEditor
          questions={postPoll}
          setQuestions={setPostPoll}
          setPollEditorVisible={setPollEditorVisible}
        />
      )}
      {gifPickerVisible && (
        <GifPickerModal setGifPickerVisible={setGifPickerVisible} insertGifs={insertGifImages} />
      )}
      {coversModal && (
        <NewsCovers
          closeModal={() => {
            setCoversModal(false);
            setCurrentStep(currentStep + 1);
          }}
          save={(cover) => {
            setPostCover(cover);
            setCoversModal(false);
            setCurrentStep(currentStep + 1);
          }}
          selectedCover={postCover}
          setSelectedCover={setPostCover}
        />
      )}
    </div>
  );
};

const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }]
  }
];
export default MWEditor;
