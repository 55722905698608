import React from 'react';
import { Forms, Notes, QA, Reviews, Resources } from './components/PagesView';

export const tabNames = {
  notes: 'Notes',
  qa: 'QA',
  forms: 'Forms',
  resources: 'Resources',
  reviews: 'Reviews'
};

const links = (courseId) =>  [
  {
    value: tabNames.forms,
    path: `/course/${courseId}/forms`,
    page: <Forms />
  },
  {
    value: tabNames.notes,
    path: `/course/${courseId}/notes`,
    page: <Notes />
  },
  {
    value: tabNames.resources,
    path: `/course/${courseId}/resources`,
    page: <Resources />
  },
  {
    value: tabNames.qa,
    path: `/course/${courseId}/qa`,
    page: <QA />
  },
  {
    value: tabNames.reviews,
    path: `/course/${courseId}/reviews`,
    page: <Reviews />
  },
];

export default links;
