import React from 'react';
import styles from './EmployeeIcons.module.scss';
import Chat from 'assets/icons/chat.svg?react';
import Share from 'assets/icons/share_svg.svg?react';
import Schedule from 'assets/icons/schedule.svg?react';
import Email from 'assets/icons/email.svg?react';
import Group from 'assets/icons/group.svg?react';
import Delete from 'assets/icons/delete.svg?react';
import Icontext from '../IconText/IconText';
import PropTypes from 'prop-types';
import { useTour } from '@reactour/tour';
const EmployeeIcons = ({ selectedEmployeesLength, displayOptions, onIconClicked }) => {
  const { currentStep, setCurrentStep } = useTour();
  const options = [
    {
      icon: <Share />,
      text: 'Share',
      id: 'share'
    },
    {
      icon: <Schedule />,
      text: 'Schedule',
      id: 'schedule'
    },
    {
      icon: <Delete />,
      text: 'Remove',
      id: 'remove'
    },

    {
      icon: <Chat />,
      text: 'Chat',
      id: 'chat'
    },
    {
      icon: <Email />,
      text: 'Email',
      id: 'email'
    }
  ];
  return (
    <div className={styles.employeeOptions}>
      {options.map((d, i) => {
        if (
          (displayOptions?.includes(d.id) || displayOptions?.includes('all')) &&
          !displayOptions?.includes(`-${d.id}`)
        )
          return (
            <Icontext
              onClick={() => {
                onIconClicked?.(d.id);
                setCurrentStep(currentStep + 1);
              }}
              key={i}
              {...d}
            />
          );
      })}
      {selectedEmployeesLength > 1 &&
        (displayOptions?.includes('team') || displayOptions?.includes('all')) &&
        !displayOptions?.includes('-team') && (
          <Icontext
            onClick={() => {
              onIconClicked?.('team');
              setCurrentStep(currentStep + 1);
            }}
            icon={<Group />}
            text="Create team"
          />
        )}
    </div>
  );
};
export default EmployeeIcons;
EmployeeIcons.propTypes = {
  employees: PropTypes.any,
  selectedEmployeesLength: PropTypes.string || PropTypes.number,
  onIconClicked: PropTypes.func,
  displayOptions: PropTypes.array
};
