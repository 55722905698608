import { handleAsync } from '../../../helpers';
import React, { useState } from 'react';
import EmployeeCard from '../EmployeeCard/EmployeeCard';
import styles from './TeamProfile.module.scss';
import EmployeeIcons from '../EmployeeIcons/EmployeeIcons';
import DeleteModal from '../DeleteModal/DeleteModal';
import AddUserModal from '../AddUserModal';
import { useKickProfilesFromTeamMutation, useDeleteTeamByIdMutation } from 'api/team';
import { showSuccessToast, showErrorToast } from 'utils/toast';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/ui';
import Slider from 'components/atoms/Slider/Slider';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
import useGetUserTierData from 'hooks/useGetUserTierData';
import { createPortal } from 'react-dom';

function TeamProfile({ team, edit, setEdit, onSaveClick }) {
  const [selectedEmployeesIds, setSelectedEmployeesIds] = useState([]);
  const isAdmin = useSelector(getIsAdminSelector);

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteTeamModal, setDeleteTeamModal] = useState(false);
  const [addModalUser, setAddModalUser] = useState(false);
  const [kickProfileFromTeam, { loading }] = useKickProfilesFromTeamMutation();
  const { refetch: refetchForTierUpdate } = useGetUserTierData({ skipFetching: true });

  const [deleteTeam] = useDeleteTeamByIdMutation();
  const navigate = useNavigate();

  const selectedAllMembers = selectedEmployeesIds?.length === team?.profiles.length;

  async function removeTeam() {
    setDeleteTeamModal(false);
    const [status] = await handleAsync(deleteTeam({ teamId: team.id }).unwrap());
    if (!status) {
      return showErrorToast('Failed to delete team');
    }
    refetchForTierUpdate();
    navigate('/teams');
    showSuccessToast('Team is deleted', 2000);
  }

  const editBtn = document.getElementById('edit-btn-steam');

  // others
  let content = (
    <>
      {editBtn && createPortal(
        <div className={styles.editBtn}>
          {edit ? (
            <div className={styles.buttons}>
              <Button bgColor="#56565675" color="#ffffffc2" onClick={() => setAddModalUser(true)}>
                Add Member
              </Button>

              {isAdmin && (
                <>
                  <Button bgColor="#56565675" color="#ffffffc2" onClick={onSaveClick}>
                    Save
                  </Button>
                  <Button
                    bgColor="#56565675"
                    color="#ffffffc2"
                    onClick={() => setDeleteTeamModal(true)}
                  >
                    Delete Team
                  </Button>
                </>
              )}
              <Button bgColor="#56565675" color="#ffffffc2" onClick={() => setEdit(false)}>
                Close
              </Button>
            </div>
          ) : (
            <>
              {isAdmin && (
                <Button bgColor="#565656db" color="#ffffffc2" onClick={() => setEdit(true)}>
                  Edit
                </Button>
              )}
            </>
          )}
        </div>,
        editBtn
      )}
      <div
        className={
          selectedEmployeesIds.length > 0 ? styles.employeeOption : styles.noOptionsSelected
        }
      >
        <EmployeeIcons
          displayOptions={['remove']}
          selectedEmployeesLength={selectedEmployeesIds.length}
          onIconClicked={(id) => {
            id === 'remove' && setDeleteModal((prev) => true);
          }}
        />
      </div>
      {deleteModal && (
        <DeleteModal
          handleClose={() => {
            setDeleteModal(false);
          }}
          handleDelete={async () => {
            setDeleteModal(false);
            let flag;
            // If we remove all members we must remove the team
            if (!selectedAllMembers) {
              flag = await handleAsync(
                kickProfileFromTeam({ teamId: team.id, profileIds: selectedEmployeesIds }).unwrap()
              );
            } else {
              removeTeam();
            }

            if (flag[0]) setSelectedEmployeesIds([]);
          }}
          title={
            selectedAllMembers
              ? ` By removing the all members, the Team will be deleted automatically. Are you sure?`
              : `Are you sure you want to remove ${selectedEmployeesIds.length > 1 ? 'these team members' : 'this team member'
              } ?`
          }
        />
      )}
      {deleteTeamModal && (
        <DeleteModal
          handleClose={() => {
            setDeleteTeamModal(false);
          }}
          handleDelete={async () => {
            removeTeam();
          }}
          title={`Are you sure you want to remove ${team?.name} team?`}
        />
      )}
      {addModalUser && (
        <AddUserModal
          teamProfiles={team?.profiles}
          teamId={team.id}
          closeModal={() => {
            setAddModalUser(false);
          }}
        />
      )}

      <div className={styles.teamtab}>
        <div className={`${styles.container} singleTeamSlider`}>
          <Slider itemsPerSlide={5}>
            {team?.profiles?.map((profile) => {
              return (
                <EmployeeCard
                  selectedEmployeesIds={selectedEmployeesIds}
                  showContactButton
                  showProfileButton
                  clickEvent={() => {
                    if (!isAdmin) return false;
                    if (selectedEmployeesIds.includes(profile.id)) {
                      setSelectedEmployeesIds(
                        selectedEmployeesIds.filter((id) => id !== profile.id)
                      );
                      return;
                    }
                    setSelectedEmployeesIds([...selectedEmployeesIds, profile.id]);
                  }}
                  height="310px"
                  extraClass={`singleCard`}
                  key={profile.id}
                  {...profile}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
  return content;
}
export default TeamProfile;
