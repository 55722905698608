import React, { useState, useEffect } from 'react';
import styles from './Awards.module.scss';
import { handleAsync } from 'helpers';
import { useDeleteAwardMutation, useSaveAwardMutation } from 'api/individual-profile';
import { useSelector } from 'react-redux';
import { getGlobalEditSelector } from 'store/individualProfile.slice';
import useUploadBlob from 'hooks/useUploadBlob';
import { Loader } from 'components/ui';
import AwardCard from '../AwardCard/AwardCard';
import Options from 'components/atoms/Options/Options';
import { Modal } from 'components/modals';
import AwardCreateModal from './AwardCreateModal';
import { showErrorToast } from 'utils/toast';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { TrashI } from 'components/ui';
import {useTranslation} from "react-i18next";

export default function Awards({ awardsData, isCurrentUserProfile }) {
  const [edit, setEdit] = useState(false);
  const [saveAward] = useSaveAwardMutation();
  const [deleteAward, { isLoading: isDeleting }] = useDeleteAwardMutation();
  const globalEdit = useSelector(getGlobalEditSelector);
  const { uploadBlob, isUploading } = useUploadBlob();
  const [modalImage, setImageModal] = React.useState('');
  const [createModalOpen, setCreateModalOpen] = React.useState(false);
  const [selectedAwardsForMultiDeletion, setSelectedAwardsForMultiDeletion] = useState([]);
  const [selectedAwardsForDeletion, setSelectedAwardsForDeletion] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setEdit(globalEdit);
  }, [globalEdit]);

  const saveAwardFun = (award) => {
    return handleAsync(saveAward({ award }).unwrap());
  };

  const handleToggleAwardSelection = ({ awardId, path }) => {
    if (selectedAwardsForMultiDeletion.some((item) => item.awardId === awardId)) {
      setSelectedAwardsForMultiDeletion(
        selectedAwardsForMultiDeletion.filter((item) => item.awardId !== awardId)
      );
    } else {
      setSelectedAwardsForMultiDeletion([...selectedAwardsForMultiDeletion, { awardId, path }]);
    }
  };

  const handleDeleteSelectedAwards = async () => {
    setSelectedAwardsForDeletion(false);

    const selectedAwardIds = selectedAwardsForMultiDeletion.map(
      (selectedAward) => selectedAward.awardId
    );

    handleAsync(deleteAward({ selectedAwardIds }).unwrap(), null, "'Error deleting awards'");
    setSelectedAwardsForMultiDeletion([]);
    setEdit(false);
  };

  async function handelAwardUpload(files) {
    const filesArray = Array.from(files);
    const uploadPromises = filesArray.map((file) => uploadBlob(file));
    const results = await Promise.allSettled(uploadPromises);
    const succeededUploads = results
      .filter((item) => item.status === 'fulfilled')
      .map((item) => item.value);
    const failedUploads = results.filter((item) => item.status !== 'fulfilled');
    if (failedUploads.length > 0) {
      const firstError = failedUploads[0].reason;
      const error = typeof firstError === 'string' ? firstError : 'Some files failed to upload';
      showErrorToast(error);
    }
    const resultsDb = succeededUploads.map((result) => {
      return saveAwardFun({
        date: new Date().toISOString(),
        image: result
      });
    });
    await Promise.all(resultsDb);

    setEdit(false);
  }

  return (
    <>
      <h3 className={styles['award-title']}>{t("Awards")}</h3>
      <div
        className={`${styles.awards} ${
          selectedAwardsForMultiDeletion.length > 0 && styles.awardsselections
        }`}
      >
        {isCurrentUserProfile && (
          <Options
            handleAdd={() => setCreateModalOpen(true)}
            extraClass="awardOptions"
            handleDiscard={() => {
              setEdit(false);
              setSelectedAwardsForMultiDeletion([]);
            }}
            handleEdit={awardsData?.length ? () => setEdit(true) : null}
            handleSave={() => setEdit(false)}
            edit={edit}
          />
        )}
        {(isUploading || isDeleting) && <Loader />}

        {awardsData?.map((item) => {
          return (
            <>
              <AwardCard
                key={item.id}
                onImageSelected={(url) => !edit && setImageModal(url)}
                imagePath={item?.image}
                {...{ edit, setEdit }}
                onToggleSelection={() =>
                  handleToggleAwardSelection({ awardId: item.id, path: item?.image })
                }
                isSelected={selectedAwardsForMultiDeletion.some(
                  (selectedAward) =>
                    selectedAward.awardId === item.id && selectedAward.path === item?.image
                )}
              />
            </>
          );
        })}
        {edit && selectedAwardsForMultiDeletion.length > 0 && (
          <div
            onClick={() => setSelectedAwardsForDeletion(true)}
            className={styles['selected-awards']}
          >
            <TrashI /> <p>{t("Delete Selected Awards")}</p>
          </div>
        )}
        {selectedAwardsForDeletion && (
          <DeleteModal
            title="are-you-sure-you-want-to-delete-these-awards"
            handleDelete={handleDeleteSelectedAwards}
            handleClose={() => {
              setSelectedAwardsForDeletion(false);
              setSelectedAwardsForMultiDeletion([]);
            }}
          />
        )}
      </div>

      {/* Create Modal */}
      <AwardCreateModal
        onSubmit={(data) => {
          const { awardFile, awardName } = data;
          const fileName = awardFile.name.split('.');
          const fileExtension = fileName[fileName.length - 1];
          // -- are used to identify the file is uploaded from the award modal
          // and in this case we can get the name easily
          const newFileName = `--${awardName}-.${fileExtension}`;
          const newFile = new File([awardFile], newFileName, { type: awardFile?.type });
          data.awardFile = newFile;
          setCreateModalOpen(false);
          return handelAwardUpload([data.awardFile]);
        }}
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      {/* View Modal */}
      <Modal
        onOutSideClick={() => setImageModal('')}
        isModalOpen={!!modalImage}
        onClose={() => setImageModal('')}
      >
        <div
          className={styles['modal-content']}
          style={{
            padding: '20px'
          }}
        >
          <div
            className={styles['image-background']}
            style={{
              backgroundImage: `url(${modalImage})`
            }}
          />
        </div>
      </Modal>
    </>
  );
}
