import React from 'react';
import Add from 'assets/icons/addicon.svg?react';
import { Transforms } from 'slate';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { object } from 'prop-types';
const gifWrapper = {
  position: 'relative'
};
const gifStyle = {
  objectFit: 'contain',
  height: '300px'
};

const deleteContent = {
  transform: 'rotate(45deg)',
  marginRight: '10px',
  cursor: 'pointer'
};

const Element = ({ attributes, children, element }) => {
  const editor = useSlateStatic();

  const deleteElement = () => {
    Transforms.removeNodes(editor, { at: ReactEditor.findPath(editor, element) });
  };
  const style = { textAlign: element.align };
  switch (element?.type) {
    case 'block-quote':
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case 'bulleted-list':
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case 'heading-one':
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case 'heading-two':
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case 'list-item':
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case 'numbered-list':
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case 'image':
      return (
        <div {...attributes} title={element.url}>
          <Add style={deleteContent} onClick={deleteElement} />
          <div style={gifWrapper} contentEditable={false}>
            <img frameBorder={0} style={gifStyle} src={element.url} alt="Gif Image" />
          </div>
          {children}
        </div>
      );
    default:
      return (
        <p style={style} {...attributes}>
          {children}
        </p>
      );
  }
};
export default Element;
