export const coursesData = [
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15274_bzggyl.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '3 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15273_hxnvbz.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '2 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15277_ov0htd.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '6 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031953/Templates/Rectangle_15275_vy0ppi.png',
    title: 'Sales Training',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '2 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1706031952/Templates/Rectangle_15276_lv1u8s.png',
    title: 'Finance and Accounting',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '1 lesson'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084641/Templates/Rectangle_15400_1_qqpf3y.png',
    title: 'Computing',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '3 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084640/Templates/Rectangle_15399_1_ebten7.png',
    title: 'Engineering',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '5 lessons'
  },
  {
    image:
      'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1710084642/Templates/Rectangle_15401_1_yrbzvc.png',
    title: 'Design',
    by: 'Matthew',
    url: 'https://',
    date: '12. 07. 2023',
    lessons: '9 lessons'
  }
];

export const startCourseData = {
  _id: '6751d0ac005c1fae229dbe19',
  title: 'test',
  subtitle: 'sub',
  skills: ['JavaScript', 'Test-Driven Development (TDD)'],
  description: 'qsdqsddd dsqd',
  banner: 'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/banner.png',
  thumbnail: 'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/content-img.png',
  chapters: [
    {
      title: 'Getting started',
      id: '1',
      uuid: '9a235c77-3790-448f-8238-f7dbfaae2ce9',
      lessons: [
        {
          title: 'Introduction',
          id: 'qsdqaa',
          content: {
            id: '851377d4-7f1c-4c1f-b59e-00fdef136cbe',
            type: 'file',
            title: 'file_example_MP4_1280_10MG.mp4',
            source:
              'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/9d6d403b-befc-4c29-8333-205fa05cb33b/1733315731822-file_example_MP4_1280_10MG.mp4',
            size: 9840497,
            mimeType: 'video/mp4',
            lastModified: 1718805943295,
            extension: 'mp4',
            duration: 120
          },
          uuid: '9d6d403b-befc-4c29-8333-205fa05cb33b',
          chapteruuid: '9a235c77-3790-448f-8238-f7dbfaae2ce9',
          resources: []
        },
        {
          title: 'What is meditation',
          id: 'dqsd',
          content: {
            id: '851377d4-7f1c-4c1f-b59e-00fdef136cbe',
            type: 'file',
            title: 'file_example_MP4_1280_10MG.mp4',
            source:
              'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/9d6d403b-befc-4c29-8333-205fa05cb33b/1733315731822-file_example_MP4_1280_10MG.mp4',
            size: 9840497,
            mimeType: 'video/mp4',
            lastModified: 1718805943295,
            extension: 'mp4',
            duration: 120,
            poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
          },
          uuid: '9d6d403b-befc-4c29-8333-205fa05cb33b',
          chapteruuid: '9a235c77-3790-448f-8238-f7dbfaae2ce9',
          resources: []
        },
        {
          title: 'Well being',
          id: 'ffqs',
          content: {
            id: '851377d4-7f1c-4c1f-b59e-00fdef136cbe',
            type: 'file',
            title: 'file_example_MP4_1280_10MG.mp4',
            source:
              'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/9d6d403b-befc-4c29-8333-205fa05cb33b/63885-508273140_small.mp4',
            size: 9840497,
            mimeType: 'video/mp4',
            lastModified: 1718805943295,
            extension: 'mp4',
            duration: 310,
            poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png'
          },
          uuid: '9d6d403b-befc-4c29-8333-205fa05cb33b',
          chapteruuid: '9a235c77-3790-448f-8238-f7dbfaae2ce9',
          resources: []
        },
        {
          title: 'Programs',
          id: 'azert',
          content: {
            id: '851377d4-7f1c-4c1f-b59e-00fdef136cbe',
            type: 'file',
            title: 'file_example_MP4_1280_10MG.mp4',
            source:
              'https://cdn.wizrx.wizrapps.com/courses/673b160469a4d539e8b8e2c2/9d6d403b-befc-4c29-8333-205fa05cb33b/1733315731822-file_example_MP4_1280_10MG.mp4',
            size: 9840497,
            mimeType: 'video/mp4',
            lastModified: 1718805943295,
            extension: 'mp4',
            poster: 'https://wizrx-frontend-mw-prod.s3.us-east-1.amazonaws.com/assets/painting.png',
            duration: 50
          },
          uuid: '9d6d403b-befc-4c29-8333-205fa05cb33b',
          chapteruuid: '9a235c77-3790-448f-8238-f7dbfaae2ce9',
          resources: []
        }
      ]
    }
  ]
};

export const courseNotes = [
  {
    title: 'Note 1',
    name: 'John Doe',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 2',
    name: 'Jane Smith',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 3',
    name: 'Sam Wilson',
    position: 'Me',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  },
  {
    title: 'Note 4',
    name: 'Alice Brown',
    position: 'Instructor',
    note: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sit amet mi in dui pellentesque placerat sit amet id urna.'
  }
];
