import { useGetTeamByIdQuery, useUpdateTeamByIdMutation } from 'api/team';
import { Loader } from 'components/ui';
import Resources from 'components/molecules/Resources/Resources';
import TeamProfile from 'components/molecules/TeamProfile/TeamProfile';
import React, { Suspense, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TeamSchedule from 'components/molecules/TeamSchedule/TeamSchedule';
import styles from './SingleTeam.module.scss';
import { Button } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import { EditInput } from 'components/form';
import { capitalizeFirstLetter, handleAsync, removeUndefinedElements } from 'helpers';
import { showErrorToast, showWarnToast } from 'utils/toast';
import { useSelector } from 'react-redux';
import { getIsAdminSelector } from 'store/login.slice';
function SingleTeam() {
  const navigate = useNavigate();
  const [edit, setEdit] = useState(false);
  const teamId = useParams().id;
  const { data, isLoading, isError, isSuccess, error } = useGetTeamByIdQuery({
    teamId
  });
  const [updateTeam] = useUpdateTeamByIdMutation();
  const isAdmin = useSelector(getIsAdminSelector);

  const [updateTeamDto, setUpdateTeamDto] = useState({
    description: data?.description || '',
    name: data?.name || ''
  });
  const handleTeamInputChange = (event) => {
    const { value, name } = event.target;
    if (name === 'description' && value.length > 500) {
      setUpdateTeamDto({ ...updateTeamDto, [name]: value.substring(0, 500) });
      return showWarnToast('Description should be less than 500 characters');
    }
    if (name === 'name' && value.length > 50) {
      setUpdateTeamDto({ ...updateTeamDto, [name]: value.substring(0, 50) });
      return showWarnToast('Name should be less than 50 characters');
    }
    setUpdateTeamDto({ ...updateTeamDto, [name]: value });
  };

  const handleSave = async () => {
    const dtoRemovedEmpty = removeUndefinedElements(updateTeamDto);
    const status = await handleAsync(
      updateTeam({ teamId: data.id, team: dtoRemovedEmpty }).unwrap()
    );
    if (status[0]) setEdit(false);
    else showErrorToast('Something wrong!');
  };
  const [tab, setTab] = useState(0);
  const tabs = [
    {
      tab: 'Team Profile',
      id: 0
    },
    {
      tab: 'Team Schedule',
      id: 1
    },
    {
      tab: 'Resource',
      id: 2
    }
  ];

  useEffect(() => {
    if (data?.id) {
      setUpdateTeamDto({
        description: data?.description || '',
        name: data?.name || ''
      });
    }
  }, [data?.id]);
  let content;
  if (isLoading) {
    content = <Loader fullpage />;
  } else if (isError) {
    content = (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  } else if (isSuccess) {
    content = (
      <Suspense fallback={<Loader fullpage={true} />}>
        <div className={styles.singleteam}>
          <div className={styles.bannerSingle}>
            <div className={styles.teamBanner}>
              {edit && isAdmin ? (
                <div className={styles.inputs}>
                  <EditInput
                    name="name"
                    id="name"
                    value={updateTeamDto?.name}
                    placeholder={'Team Name'}
                    onChange={handleTeamInputChange}
                  />
                  <EditInput
                    value={updateTeamDto?.description}
                    name="description"
                    id="description"
                    onChange={handleTeamInputChange}
                    placeholder={'Team Description'}
                  />
                </div>
              ) : (
                <div>
                  <h3 className={styles.title}>
                    {data?.name}
                  </h3>
                  <span className={styles.team_description}>{data?.description}</span>
                </div>
              )}
            </div>
            <div id='edit-btn-steam' className={styles.edit_btn_portaled}></div>
          </div>
          <div className={styles.container}>
            <div className={styles.tabsHolder}>
              <div className={tab === 2 ? `${styles.tabs} ${styles.tabsdown}` : `${styles.tabs}`}>
                {tabs.map((item) => {
                  return (
                    <Button
                      key={item.id}
                      bgColor={tab === item.id ? '#2e6055' : 'transparent'}
                      color={tab === item.id ? 'white' : '#c0c0c0'}
                      onClick={() => {
                        setTab(item.id);
                        setEdit(false);
                      }}
                    >
                      {item.tab}
                    </Button>
                  );
                })}
                <Button bgColor="transparent" color="#c0c0c0" onClick={() => navigate('/teams')}>
                  All Teams
                </Button>
              </div>
            </div>
            {tab === 0 && (
              <TeamProfile onSaveClick={handleSave} edit={edit} setEdit={setEdit} team={data} />
            )}
            {tab === 1 && <TeamSchedule team={data} />}
            {tab === 2 && <Resources id={teamId} resources={data.resources} />}
          </div>
          {/* {tab === 1 && <Employees isTeam />} */}
        </div>
      </Suspense>
    );
  }

  return content;
}
export default SingleTeam;
