import React from 'react'

export default function index({ activeMedia }) {
  return (
    <video src={activeMedia.src} poster={activeMedia.poster} controls autoPlay autoSave='true' muted>
      <source src={activeMedia.src} type="video/mp4" />
      <track src={activeMedia.src} kind="captions" srcLang="en" label="English" default />
      Your browser does not support the video tag.
    </video>
  )
}
