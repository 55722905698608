import { showErrorToast } from 'utils/toast';
import {
  useUpdateBioDescriptionMutation,
  useUpdateProfileVideoByUserIdMutation
} from 'api/individual-profile';
import { deleteFromS3, handleAsync } from 'helpers';
import { useUploadBlobs } from 'hooks';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';

function useAboutMeUtils({ aboutDescription, videoLink }) {
  const loggedInProfileId = useSelector(getCurrentUserProfileId);
  const profileId = useParams()?.id || loggedInProfileId;
  const isCurrentUserProfile = loggedInProfileId === profileId;
  const [description, setDescription] = useState(aboutDescription);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const { uploadBlobs, isUploading, totalUploadProgress } = useUploadBlobs();
  const [updateUserDescription] = useUpdateBioDescriptionMutation();
  const [updateProfileVideo] = useUpdateProfileVideoByUserIdMutation();
  async function handelUpload(file) {
    if (!file?.type.includes('video')) {
      showErrorToast('Please upload a video file');
      return;
    }
    setVideoBlob(file);
  }

  const handleVideoUpload = async () => {
    if (!videoBlob) return;
    // upload the video
    const [status, response] = await handleAsync(uploadBlobs([videoBlob], 'about_video'));
    const keys = response?.keys ?? [];
    // check if the video was uploaded
    if (!status) {
      const error = typeof response === 'string' ? response : 'Error uploading video';
      return showErrorToast(error);
    }
    // update the video link
    const result = await handleAsync(updateProfileVideo({ videoKey: keys[0] }).unwrap());
    // check if the video link was updated successfully and delete the old video
    if (result[0] && videoLink) {
      handleAsync(deleteFromS3(videoLink));
    }
  };
  return {
    isCurrentUserProfile,
    description,
    setDescription,
    isModalOpen,
    setIsModalOpen,
    isUploading,
    totalUploadProgress,
    updateUserDescription,
    handelUpload,
    handleVideoUpload
  };
}

export default useAboutMeUtils;
