import { MAX_TEAM_RESOURCES_REGULAR } from 'constants';
import { MAX_EVENT_ATTACHMENTS_LITE } from 'constants';
import { MAX_FILE_SIZE_LITE } from 'constants';
import { MAX_VIDEO_SIZE_LITE } from 'constants';
import { MAX_VIDEO_SIZE_REGULAR } from 'constants';
import { MAX_FILE_SIZE_REGULAR } from 'constants';
import { MAX_EVENT_PARTICIPANTS_LITE } from 'constants';
import { MAX_CREATED_TEAMS_LITE } from 'constants';
import { MAX_TEAM_RESOURCES_LITE } from 'constants';
import { APP_TIERS } from 'constants';

export const isUserLiteTier = (tierData) => {
  return tierData?.tier === APP_TIERS.LITE;
};

export const isUserRegularTier = (tierData) => {
  return tierData?.tier === APP_TIERS.REGULAR;
};
export const checkTeamResourcesLimit = (tierData, teamId, filesToUploadCount) => {
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  const uploadedFilesCount = tierData?.teamResourcesAdded?.[teamId] ?? 0;

  if (
    isLiteTier &&
    uploadedFilesCount &&
    uploadedFilesCount + filesToUploadCount > MAX_TEAM_RESOURCES_LITE
  ) {
    return [
      false,
      `Your license does not allow adding more than ${MAX_TEAM_RESOURCES_LITE} resources`
    ];
  } else if (!isLiteTier && uploadedFilesCount + filesToUploadCount > MAX_TEAM_RESOURCES_REGULAR) {
    return [
      false,
      `Your license does not allow adding more than ${MAX_TEAM_RESOURCES_REGULAR} resources`
    ];
  }

  return [true, ''];
};

export const checkTeamCreationLimit = (tierData) => {
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  const createdTeamsCount = tierData?.teamsCreated ?? 0;
  if (isLiteTier && createdTeamsCount >= MAX_CREATED_TEAMS_LITE) {
    return [false, 'team-creation-error'];
  }

  return [true, ''];
};

export const checkEventAttachmentsLimit = (tierData, filesToUploadCount) => {
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  if (isLiteTier && filesToUploadCount > MAX_EVENT_ATTACHMENTS_LITE) {
    return [
      false,
      `Your license does not allow adding more than ${MAX_EVENT_ATTACHMENTS_LITE} files`
    ];
  }

  return [true, ''];
};

export const checkEventParticipantsLimit = (tierData, participantsCount) => {
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  // make sure the participants count is not more than the limit
  if (isLiteTier && participantsCount > MAX_EVENT_PARTICIPANTS_LITE) {
    return [
      false,
      `Your license does not allow adding more than ${MAX_EVENT_PARTICIPANTS_LITE} participants`
    ];
  }

  return [true, ''];
};

export const checkLastCreatedEventDate = (tierData) => {
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  const lastEventCreated = tierData?.eventsCreated ? tierData?.eventsCreated : {};
  const lastCreatedEventDate = Object.keys(lastEventCreated)[0];
  // only 1 event per day:
  if (isLiteTier && lastCreatedEventDate && isSameDay(new Date(lastCreatedEventDate), new Date())) {
    return [false, 'Your license does not allow creating more than one event per day'];
  }

  return [true, ''];
};

const isSameDay = (date1, date2) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

export const checkFileUploadLimit = (tierData, files) => {
  // regular user can upload 12MBs and 500MBs for images and videos respectively
  // lite user can upload 6MBs and 100MBs for images and videos respectively
  const isLiteTier = tierData?.tier === APP_TIERS.LITE;
  const images = files.filter((file) => file?.type.includes('image'));
  const videos = files.filter((file) => file?.type.includes('video'));

  // check that each image file has a size less than the limit 6 for lite and 12 for regular
  const isImagesValid = images.every((image) => {
    if (isLiteTier && image.size > MAX_FILE_SIZE_LITE) {
      return false;
    } else if (!isLiteTier && image.size > MAX_FILE_SIZE_REGULAR) {
      return false;
    }
    return true;
  });

  // check that each video file has a size less than the limit 100 for lite and 500 for regular
  const isVideosValid = videos.every((video) => {
    if (isLiteTier && video.size > MAX_VIDEO_SIZE_LITE) {
      return false;
    } else if (!isLiteTier && video.size > MAX_VIDEO_SIZE_REGULAR) {
      return false;
    }
    return true;
  });

  let customMessage =
    isLiteTier && !isImagesValid
      ? `Your license does not allow uploading files bigger than ${
          MAX_FILE_SIZE_LITE / (1024 * 1024)
        }MBs`
      : `Your license does not allow uploading files bigger than ${
          MAX_FILE_SIZE_REGULAR / (1024 * 1024)
        }MBs`;

  if (!isImagesValid) {
    return [false, customMessage];
  }

  customMessage =
    isLiteTier && !isVideosValid
      ? `Your license does not allow uploading videos bigger than ${
          MAX_VIDEO_SIZE_LITE / (1024 * 1024)
        }MBs`
      : `Your license does not allow uploading videos bigger than ${
          MAX_VIDEO_SIZE_REGULAR / (1024 * 1024)
        }MBs`;
  if (!isVideosValid) {
    return [false, customMessage];
  }

  return [true, ''];
};
