import React, { useLayoutEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import links from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import NavTabs from 'components/atoms/NavTabs/NavTabs';
import MediaList from 'components/molecules/MediaList/MediaList';
import Back from 'assets/icons/back.svg?react';
import Arrow from 'assets/icons/arrowrotate.svg?react';
import Share from 'assets/icons/share_svg.svg?react';
import Favourite from 'assets/icons/fav.svg?react';
import { Button } from 'components/ui';
import { startCourseData } from 'constants/courses';


export default function Course() {
  const { tab, courseId } = useParams();

  const courseData = startCourseData;

  const { title, banner } = courseData;
  
  const courseLinks = links(courseId);
  const navigate = useNavigate();
  const validTab = courseLinks.find((link) => link.path === `/course/${courseId}/${tab}`);
  const [isOpen, setIsOpen] = useState(true);

  useLayoutEffect(() => {
    if (!tab || !validTab) {
      navigate('forms');
      return;
    }
  }, [tab, navigate, validTab]);

  const toggleContainer = () => setIsOpen(!isOpen);

  return (
    <>
      <ResponsiveWrapper extraClass="courseWrapper">
        <div className={styles.courseBanner} style={{
          background: 'url(' + banner + ') no-repeat center center',
        }}></div>
        <div className={styles.mainContainer}>
          <button className={styles.backButton}>
            <Back onClick={() => navigate('/courses')} /> {title}
          </button>
          <MediaList course={courseData} />
          <div className={styles.topContainer}>
            <NavTabs links={courseLinks} extraClass="courseTabs" />
            <div className={styles.buttons}>
              <Button color="#E1E1E1" bgColor="#333">
                <Favourite />
                Favourite
              </Button>
              <Arrow
                onClick={toggleContainer}
                className={`${styles.arrow} ${isOpen ? styles.arrowOpen : ''}`}
              />
            </div>
          </div>
          <div className={`${styles.container} ${isOpen ? styles.open : styles.closed}`}>
            <main className={styles.view_container}>{validTab?.page || courseLinks[0].page}</main>
          </div>
        </div>
      </ResponsiveWrapper>
    </>
  );
}
