import { Editor, Element as SlateElement, Transforms } from 'slate';
import { TEXT_ALIGN_TYPES, LIST_TYPES } from './constants';
import { handleAsync } from 'helpers';
import { uploadBlobToAWS } from 'helpers';
import axios from 'axios';
import fetchInstance from 'utils/api';

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type'
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n?.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    newProperties = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = 'type') => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const checkTextStyle = (data) => {
  let text = data.text;
  if (data?.bold) text = `<b>${text}</b>`;
  if (data?.italic) text = `<i>${text}</i>`;
  if (data?.underline) text = `<u>${text}</u>`;
  if (data?.code) text = `<code>${text}</code>`;

  return text;
};

// TODO: there has to be a better way to parse this.
const checkStyle = (value) => {
  let text = '';
  value.children.forEach((t) => {
    let currentText = t.text || '';

    if (t?.type == 'list-item') {
      t.children.forEach((a) => {
        currentText += checkTextStyle(a);
      });
      currentText = `<li style="text-align:${t.align || 'left'}">${currentText}</li>`;
    } else {
      currentText = checkTextStyle(t);
    }

    text += currentText;
  });

  return text;
};
// TODO: there has to be a better way to parse this.
const checkBlock = (text, type, url, align) => {
  switch (type) {
    case 'heading-one':
      text = `<h1 style="text-align:${align || 'left'}">${text}</h1>`;
      break;
    case 'heading-two':
      text = `<h2 style="text-align:${align || 'left'}">${text}</h2>`;
      break;
    case 'block-quote':
      text = `<blockquote style="text-align:${align || 'left'}">${text}</blockquote>`;
      break;
    case 'numbered-list':
      text = `<ol>${text}</ol>`;
      break;
    case 'bulleted-list':
      text = `<ul>${text}</ul>`;
      break;
    case 'image':
      text = `<div style="width:100%;position:relative; margin-top:10px"><img  height="300px" object-fit="contain" style=" object-fit:contain;" frameBorder="0" allowFullScreen src=${url} frameBorder="0" alt='Gif Image' ></img></div>`;
      break;
    default:
      text = `<p style="text-align:${align || 'left'}">${text}</p>`;
      break;
  }
  return text;
};
// TODO: there has to be a better way to parse this.
const serialize = (value) => {
  if (!Array.isArray(value)) return [];
  let t = value
    .map((n) => {
      let text = '';
      text = checkStyle(n);
      text = checkBlock(text, n?.type, n.url, n.align);
      return text;
    })
    .join('');
  return t;
};

const downloadImage = async (imageUrl) => {
  try {
    const response = await fetchInstance.get(imageUrl, {
      responseType: 'blob'
    });

    return response;
  } catch (error) {
    throw error;
  }
};

const handleDonwloadAndUploadPostCover = async (url, companyId) => {
  const [downloadStatus, blob] = await handleAsync(downloadImage(url));
  const file = new File([blob], 'postCover.webp');
  const [uploadStatus, result] = await handleAsync(uploadBlobToAWS(file, `post-covers`));
  return result;
};

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const convertStringToParagraph = (str) => {
  return [
    {
      type: 'paragraph',
      children: [{ text: str }]
    }
  ];
};

export {
  toggleBlock,
  toggleMark,
  isBlockActive,
  isMarkActive,
  serialize,
  handleDonwloadAndUploadPostCover,
  isJsonString,
  convertStringToParagraph
};
