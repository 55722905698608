import { answerTypes } from 'store/quiz.slice';

const { multiChoice, singleChoice, textAns, pictureChoice } = answerTypes;

export const isAnswerCorrect = (question, userAnswer) => {
  const correctAnswer = question.answer?.[question?.type] || null;

  if (question?.type === singleChoice || question?.type === pictureChoice) {
    return correctAnswer === userAnswer?.id;
  }

  if (question?.type === multiChoice) {
    const userAnswerIds = userAnswer?.map((ans) => ans.id) || [];
    return (
      Array.isArray(correctAnswer) &&
      correctAnswer.length === userAnswerIds.length &&
      correctAnswer.every((id) => userAnswerIds.includes(id))
    );
  }

  if (question?.type === textAns) {
    return undefined;
  }

  return false;
};
