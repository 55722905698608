import React, { useState } from 'react';
import NotesEditor from './Editor';
import styles from '../../../styles.module.scss';
import style from './styles.module.scss';
import { CustomDropdown } from 'components/form';
import NoteCard from './NoteCard';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';
import { courseNotes as initialCourseNotes } from 'constants/courses';

export default function Index() {
  const [notes, setNotes] = useState([]);
  const [courseNotes, setCourseNotes] = useState(initialCourseNotes);
  const [data, setData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [noteToDelete, setNoteToDelete] = useState(null);
  const [filter, setFilter] = useState('myNotes');

  const handleEdit = (updatedNote, index) => {
    const updatedNotes = [...notes];
    updatedNotes[index] = updatedNote;
    setNotes(updatedNotes);
  };

  const handleAddNote = (newNote) => {
    setNotes([newNote, ...notes]);
    setData('');
  };

  const handleFilterChange = (selectedOption) => {
    setFilter(selectedOption === 'All Notes' ? 'all' : 'myNotes');
  };

  const handleDeleteNote = () => {
    if (filter === 'all') {
      setCourseNotes(courseNotes.filter((note) => note !== noteToDelete));
    } else {
      setNotes(notes.filter((note) => note !== noteToDelete));
    }
    setDeleteModal(false);
    setNoteToDelete(null);
  };

  const dropdownOptions = [
    { text: 'All Notes', label: 'All Notes' },
    { text: 'My Notes', label: 'My Notes' }
  ];

  const filteredNotes =
    filter === 'all' ? courseNotes : notes.filter((note) => note.position === 'Me');
  const notesLength = filteredNotes.length;

  return (
    <div>
      <div className={style.topWrapper}>
        <h1 className={styles.sectionTitle}>Notes ({notesLength})</h1>
        <CustomDropdown
          list={dropdownOptions}
          value={filter === 'all' ? 'All Notes' : 'My Notes'}
          onChange={handleFilterChange}
          placeholder="Select Notes"
          extraClass="notesDropdown"
        />
      </div>

      {filter === 'all' ? (
        <div className={style.notesContainer}>
          {courseNotes.map((note, index) => (
            <NoteCard
              key={index}
              title={note.title}
              note={note.note}
              name={note.name}
              position={note.position}
              handleEdit={(updatedNote) => handleEdit(updatedNote, index)}
              handleDelete={() => {
                setDeleteModal(true);
                setNoteToDelete(note);
              }}
            />
          ))}
        </div>
      ) : (
        <>
          <NotesEditor
            placeholderText="Add a note..."
            data={data}
            setData={setData}
            onAddNote={handleAddNote}
          />
          <div className={style.notesContainer}>
            {notes.map((note, index) => (
              <div key={index} className={style.note}>
                {note.map((block, i) => (
                  <div key={i} style={{ textAlign: block.align || 'left' }}>
                    {block.children.map((leaf, j) => (
                      <span
                        key={j}
                        style={{
                          fontWeight: leaf.bold ? 'bold' : 'normal',
                          fontStyle: leaf.italic ? 'italic' : 'normal',
                          textAlign: block.align || 'left'
                        }}
                      >
                        {leaf.text}
                      </span>
                    ))}
                    {block?.type === 'image' && <img src={block.url} alt="media" />}
                    {block?.type === 'video' && (
                      <video controls>
                        <source src={block.url} type="video/mp4" />
                      </video>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </>
      )}

      {deleteModal && (
        <DeleteModal
          handleClose={() => setDeleteModal(false)}
          handleDelete={handleDeleteNote}
          title="Are you sure you want to delete this note?"
        />
      )}
    </div>
  );
}
