import React from 'react';
import styles from './DeleteModal.module.scss';
import Trash from 'assets/icons/delete.svg?react';
import { Button } from 'components/ui';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
export default function DeleteModal({ title, handleClose, handleDelete, colorBgSwitcher }) {
  const { t } = useTranslation();

  const modalRoot = document.getElementById('modal-root');
  return (
    modalRoot && createPortal(<div
      className={`${styles.container} ${colorBgSwitcher ? styles[`bg-${colorBgSwitcher}`] : ''}`}
    >
      <div className={styles.modal}>
        <Trash />
        <div className={styles.content}>
          <p className={styles.title}>{title ? t(title) : t('are-you-sure')}</p>
        </div>
        <div className={styles.buttons}>
          <Button color="#a2a2a2" onClick={handleClose} colorBgSwitcher={colorBgSwitcher}>
            {t('Cancel')}
          </Button>
          <Button color="#079677" onClick={handleDelete} colorBgSwitcher={colorBgSwitcher}>
            {t('Delete')}
          </Button>
        </div>
      </div>
    </div>, modalRoot)

  );
}
