import React, { useRef, useState } from 'react';
import styles from './AddCommentEditor.module.scss';
import Emoji from '../../../assets/icons/emoji.svg?react';
import Arrow from '../../../assets/icons/arrow.svg?react';
import Discard from '../../../assets/icons/addicon.svg?react';
import {
  useCreateCommentMutation,
  useUpdateCommentByIdMutation
} from 'api/social-board/commentSlice';
import Picker from 'emoji-picker-react';
import Add from 'assets/icons/addicon.svg?react';
import { getS3Url, deleteFromS3 } from 'helpers/s3Handler';
import { handleAsync, isImageURL } from 'helpers/index.js';
import { Theme } from 'emoji-picker-react';
import ReactTextareaAutosize from 'react-textarea-autosize';
import { showSuccessToast, showErrorToast } from 'utils/toast';
import { MEDIA_TYPES } from 'constants';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { getUserStatusSelector } from 'store/login.slice';
import { Loader } from 'components/ui';
export default function AddCommentEditor({
  postCurrentPage,
  placeholder,
  postId,
  comment,
  setComment,
  showComments,
  isUpdating,
  cancel,
  commentId,
  mediaLinks,
  uploadBlobs
}) {
  const userStatus = useSelector(getUserStatusSelector);
  const names = userStatus.user.fullName.split(' ');
  const firstName = names[0];
  const [showPicker, setShowPicker] = useState(false);
  const [postMedia, setPostMedia] = useState([]);
  const [mediaToBeDeleted, setMediaToBeDeleted] = useState([]);
  const [createComment, { isLoading: isSaving }] = useCreateCommentMutation();
  const [updateCommentById, { isLoadingUpdates }] = useUpdateCommentByIdMutation();
  const inputRef = useRef();
  const inputFileRef = React.useRef(null);
  const saveComment = debounce(async () => {
    if (inputRef.current.value.trim() === '' && !postMedia.length) {
      return showErrorToast('Comment cannot be empty');
    }
    let mediaToBeSaved = [];
    // const [status, { keys: succeededOnes }] = await handleAsync(
    const [status, result] = await handleAsync(
      uploadBlobs(postMedia?.map((media) => media.file) ?? [], 'postContents')
    );
    const succeededOnes = result?.keys ?? [];
    if (!status) {
      const error = typeof result === 'string' ? result : 'Error uploading content';
      return showErrorToast(error);
    }
    mediaToBeSaved = succeededOnes.map((item) => ({
      type: item.split('.').pop(),
      link: item
    }));

    if (isUpdating) {
      showSuccessToast('Updating Comment');
      if (mediaToBeDeleted) {
        mediaToBeDeleted;
        mediaToBeDeleted.forEach((media) => {
          cleanUploads(media.link);
        });
      }
      //append our previous media
      for (const media of mediaLinks) {
        if (!mediaToBeDeleted.includes(media)) {
          mediaToBeSaved.push(media);
        }
      }
      await handleAsync(
        updateCommentById({
          commentId: commentId,
          comment: {
            postId: postId,
            media: mediaToBeSaved ?? [],
            content: JSON.stringify(inputRef.current.value),
            userId: userStatus.user._id,
            companyId: userStatus.company.id,
            profile: userStatus.profileStatus.id
          }
        }).unwrap()
      );
      cancel();
    } else {
      showSuccessToast('Saving Comment');
      const [status] = await handleAsync(
        createComment({
          page: postCurrentPage,
          comment: {
            postId: postId,
            media: mediaToBeSaved ?? [],
            content: JSON.stringify(inputRef.current.value),
            userId: userStatus.user._id,
            companyId: userStatus.company.id,
            profile: userStatus.profileStatus.id
          }
        }).unwrap()
      );
      if (!status) {
        mediaToBeSaved.forEach((media) => {
          cleanUploads(media.link);
        });
      }
      showComments?.();
      cancel?.();
      setPostMedia([]);
      setComment('');
    }
  }, 400);
  const addEmoji = (emoji) => {
    const cursor = inputRef.current.selectionStart;
    const value =
      inputRef.current.value.slice(0, cursor) + emoji.emoji + inputRef.current.value.slice(cursor);
    setComment(value);
    setShowPicker(false);
  };

  const handleFileAddition = (files, filesToBeAdded, index) => {
    if (index >= files.length) {
      inputFileRef.current.value = '';
      setPostMedia([...postMedia, ...filesToBeAdded]);
      return;
    }
    let file = files[index];
    const objectUrl = URL.createObjectURL(file);
    if (file?.type.includes('image/')) {
      filesToBeAdded.push({ file, objectUrl, type: MEDIA_TYPES.IMAGE });
    }
    if (file?.type.includes('video/')) {
      filesToBeAdded.push({ file, objectUrl, type: MEDIA_TYPES.VIDEO });
    }
    handleFileAddition(files, filesToBeAdded, index + 1);
  };
  const removeMedia = (media) => {
    URL.revokeObjectURL(media.objectUrl);
    setPostMedia(
      postMedia.filter((current) => {
        return current.objectUrl !== media.objectUrl;
      })
    );
  };

  async function cleanUploads(link) {
    //DO NOT REMOVE! THIS DELETION IS DONE FOR UPDATES AND FAILED UPLOADS
    await handleAsync(deleteFromS3(link));
  }

  function toggleMediaToBeDeleted(url) {
    if (mediaToBeDeleted.includes(url)) {
      setMediaToBeDeleted(
        mediaToBeDeleted.filter((current) => {
          return current !== url;
        })
      );
    } else {
      setMediaToBeDeleted([...mediaToBeDeleted, url]);
    }
  }
  if (isSaving) return <Loader section={true} />;
  if (isLoadingUpdates) return <Loader section={true} />;
  return (
    <div className={styles.container}>
      <div className={styles.subcontainer}>
        <div className={styles.emojicontainer}>
          <Emoji
            className={styles.emoji}
            onClick={() => {
              setShowPicker(!showPicker);
            }}
          />
          {showPicker && (
            <div className={styles.pickercontainer}>
              <Picker className={styles.picker} onEmojiClick={addEmoji} theme={Theme.DARK} />
            </div>
          )}
        </div>
        <div className={styles.commentHolder}>
          <div
            className={
              isUpdating ? `${styles.inputHolder} ${styles.editHolder}` : styles.inputHolder
            }
          >
            <ReactTextareaAutosize
              maxLength={300}
              minLength={1}
              placeholder={!isUpdating ? placeholder + firstName : ''}
              ref={inputRef}
              onSubmit={saveComment}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  saveComment();
                  e.preventDefault();
                }
              }}
            />
          </div>
          <div className={styles.iconsHolder}>
            {isUpdating && (
              <button className={styles.discard} onClick={() => cancel()} name="cancel">
                <Discard />
              </button>
            )}
            <button className={styles.arrow} onClick={saveComment} name="save">
              <Arrow />
            </button>
            <div className={styles.input}>
              <input
                style={{ display: 'none' }}
                type="file"
                accept="video/*,image/*"
                id={`upload${postId}`}
                ref={inputFileRef}
                multiple={true}
                onChange={(e) => handleFileAddition(e.target.files, [], 0)}
              />
              <label htmlFor={`upload${postId}`}>
                <Add className={styles.addcontent} />
              </label>
            </div>
          </div>
        </div>
      </div>
      {postMedia.length || mediaLinks?.length ? (
        <div className={styles.files}>
          {postMedia.map((media, index) => {
            return (
              <div className={styles.mediacontainer} key={media.objectUrl}>
                <Add
                  className={styles.deletecontent}
                  onClick={() => {
                    removeMedia(media);
                  }}
                />
                {media?.type === MEDIA_TYPES.VIDEO ? (
                  <video controls src={media.objectUrl} />
                ) : (
                  <img src={media.objectUrl} alt="dynamic image" />
                )}
              </div>
            );
          })}
          {mediaLinks?.map((media, index) => {
            const url = getS3Url(media.link);
            const extention = url.split('.').pop();
            const urlIsImage = isImageURL(extention.toLowerCase());
            if (mediaToBeDeleted.includes(media)) {
              return <></>;
            }
            return (
              <div
                className={
                  mediaToBeDeleted.includes(media)
                    ? styles.mediacontainerdelete
                    : styles.mediacontainer
                }
                key={url}
              >
                <Add
                  className={styles.deletecontent}
                  onClick={() => {
                    toggleMediaToBeDeleted(media);
                  }}
                />
                {urlIsImage ? <img src={url} alt="dynamic image" /> : <video controls src={url} />}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
