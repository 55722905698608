import React from 'react';
import styles from './PageHeader.module.scss';
import { getTheme } from 'helpers/general';
import { useSelector } from 'react-redux';
import { getCompanyThemeSelector } from 'store/login.slice';
function PageHeader({ listItems, fixed }) {
  const companyTheme = useSelector(getCompanyThemeSelector);
  const theme = getTheme(companyTheme, 'companyPage');

  return (
    <>
      <ul
        className={styles['page-header']}
        style={{
          position: fixed ? 'sticky' : 'relative',
          top: fixed ? `0` : 'auto',
          left: fixed ? '0' : 'auto',
          zIndex: fixed ? '999' : 'auto',
          background: theme.bannerGradient
        }}
      >
        {listItems.map((item) => (
          <li className={styles.scrollToDiVid} key={item}>
            <a href={`#company-${item.toLocaleLowerCase()}`}>{item}</a>
          </li>
        ))}
      </ul>
    </>
  );
}

export default PageHeader;
