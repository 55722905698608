import React, { memo, useState } from 'react';
import { AiOutlineEye, AiOutlineClose } from 'react-icons/ai';
import { MdDelete } from 'react-icons/md';
import styles from './UploaderPreview.module.scss';
import { trimFileName } from 'helpers/general';
import FilePreview from '../FilePreview/FilePreview';

const UploaderPreview = memo(({ file, onDelete }) => {
    const [showPreview, setShowPreview] = useState(false);

    const handlePreview = () => {
        setShowPreview(true);
    };

    const renderPreview = () => {
        if (file?.type.startsWith('image')) {
            return <img src={URL.createObjectURL(file)} alt={file.name} className={styles.fileThumbnail} />;
        } else if (file?.type.startsWith('video')) {
            return <video src={URL.createObjectURL(file)} controls className={styles.fileThumbnail} />;
        } else if(file?.type.split('/')[1] === 'pdf'){
            return <embed src={URL.createObjectURL(file)} type="application/pdf" className={styles.fileThumbnail} />;
        } else {
            return <div className={styles.fileThumbnail}>File preview not supported</div>;
        }
    };

    const previewContent = {
        path: URL.createObjectURL(file),
        type: 'file',
        ext: file?.type.split('/')[1],
        title: file.name,
    }

    return (
        <div className={styles.fileItem}>
            {renderPreview()}
            <div className={styles.filename}>{trimFileName(file.name, 9)}</div>
            <div className={styles.iconContainer}>
                <button className={styles.previewBtn} onClick={handlePreview}>
                    <AiOutlineEye size={20} />
                </button>
                <button className={styles.deleteBtn} onClick={onDelete}>
                    <MdDelete size={20} />
                </button>
            </div>

            <FilePreview isOpen={showPreview} onClose={() => setShowPreview(false)} content={previewContent} />
        </div>
    );
});

export default UploaderPreview;

